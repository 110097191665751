<template>
    <component :is="template">
        <v-responsive width="800px" class="mx-auto member-orders py-9 px-0 px-md-3">
            <v-layout justify-center><span class="headline">포인트 충전하기</span></v-layout>

            <v-card outlined class="mt-6">
                <v-card-title class="d-block">
                    <div class="text-center"><b>{{me?.name}}</b>님</div>
                    <div class="text-center">보유 포인트<span class="primary--text"><b> {{ me?.point?.format?.() }}</b></span>원</div>
                </v-card-title>
            </v-card>
            
            <v-card elevation="0" class="mt-6">
                <v-card-title class="d-flex justify-center">
                    <v-icon left>mdi-gift</v-icon> 포인트 충전권
                </v-card-title>

                <v-divider />
                <v-card-text>
                    <v-card v-for="pickup in order.pickups" :key="pickup._id" outlined class="mt-2">
                        <v-card-title class="d-flex">
                            <span class="subtitle-1">{{ pickup.name }}</span>
                            <v-spacer />
                            <span class="d-inline-block d-flex align-center">
                                <quantity-input v-model="pickup.quantity" />
                            </span>
                        </v-card-title>
                    </v-card>
                </v-card-text>

                <v-divider />

                <v-card-title class="justify-center pb-0">
                    <label class="mr-2">결제금액</label><span class="primary--text">{{ order.paymentAmount?.format?.() }}</span>원
                </v-card-title>
                <v-card-title class="justify-center align-center">
                    <label class="mr-2">충전포인트</label><span class="primary--text">{{ order.pointAmount?.format?.() }}</span><v-icon color="primary">mdi-alpha-p-circle</v-icon>
                </v-card-title>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    
                    <template v-for="paymentMethod, index in siteInfo.paymentMethods">
                        <v-btn v-if="paymentMethod == `mobile` && siteInfo.paymentGateways[index] == `smartro` " :key="index" color="primary" @click="order.paymentGateway = siteInfo.paymentGateways[index], pay()">
                            <span v-if="siteInfo.paymentGateways[index] == `smartro`">스마트로 모바일 결제</span>
                        </v-btn>
                    </template>
                    <v-spacer />
                </v-card-actions>
            </v-card>

            <payment-gateway ref="paymentGateway" @complete="complete" />

        </v-responsive>
    </component>
</template>

<script>
import api from "@/api";
import QuantityInput from '@/components/client/mypage/quantity-input.vue';
import PaymentGateway from "@/components/client/gshop/payment-gateway.vue";
export default {
    components: { 
        QuantityInput,
        PaymentGateway
    },
    data(){
        return {

            filter: {},

            page: 1,
            pageCount: 0,
            limit: 10,

            ordersHeaders: [
                { text: "주문번호", value: "orderNo", align: "center", width: 100 },
                { text: "구매상품", value: "purchases", align: "center" },
                { text: "주문금액", value: "totalAmount", align: "center", width: 150 },
                { text: "발행상태", value: "status", align: "center", width: 100 },
                { text: "결제상태", value: "paymentStatus", align: "center", width: 100 },
                { text: "주문일자", value: "createdAt", align: "center", width: 180 },
            ],

            order: null,

            pointItems: [],

            me: null,
            receiver: {
                phone: ""
            }
        }
    },
    created(){
        console.log(this.$theme.pages);
    },
    mounted(){
        if(this.logoff) this.$router.push(`/login`);
        this.init();
    },
    methods: {
        async init(){
            await this.$store.dispatch("siteInfo");

            let { me } = await api.v1.me.profile.get();
            
            this.me = {
                name: me.name,
                phone: me.phone,
                email: me.email,
                point: me.point
            };

            let { pointItems } = await api.v1.gshop.pointItems.gets();

            this.order = {
                pickups: pointItems.map(pointItem => ({ ...pointItem, _pointItem: pointItem._id, quantity: 0 })),
                type: "point",
                paymentMethod: "mobile",
                buyer: this.me,
                receiver: this.me,
                get paymentAmount(){
                    var amount = 0;
                    for(let pickup of this.pickups){
                        amount += pickup.salePrice * pickup.quantity;
                    }
                    return amount;
                },
                get pointAmount(){
                    var amount = 0;
                    for(let pickup of this.pickups){
                        amount += pickup.faceamt * pickup.quantity;
                    }
                    return amount;
                }
            };
        },

        async pay(){
            let { order } = await api.v1.gshop.orders.post(this.order);

            this.$refs.paymentGateway.order = order;
            this.$refs.paymentGateway.pay();
        },

        complete(payload){
            alert(`[${payload.resCode}] ${payload.resMessage}`);
            
            window.location.reload();
        }
    },
    computed: {
        template(){ return () => import(`@/templates/${this.$theme.pages.point}`) },
        
        logoff(){
            return !this.$store.state.accessToken;
        },
        siteInfo(){
            return this.$store.state.siteInfo;
        }
    }
}
</script>

<style scoped>
.member-orders .title {
    font-size: 22px !important;
    font-weight: 600;
}
.member-orders >>> .v-data-table__mobile-row {
    height: auto;
}
</style>